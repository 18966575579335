<template>
  <section id="settings" class="route">
    <!-- Heading -->
    <h1 class="title">
      <span>Settings</span>
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right grey-light--text"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">{{ routeName }}</span>
    </h1>

    <!-- Route Content -->
    <router-view></router-view>
  </section>
</template>

<script>
import PermissionsMixin from "../components/mixins/permissions.mixin";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";
import { routeName } from "../models/permissions";

export default {
  name: "Settings",

  mixins: [PermissionsMixin, SubrouteManagerMixin],

  computed: {
    routeName() {
      return routeName(this.$route.name) || "Home";
    },

    subroutes() {
      return [
        {
          name: "ListEvents",
          icon: "fas fa-megaphone grey-light--text",
          condition: true
        },
        {
          name: "ListClasses",
          icon: "fas fa-presentation grey-light--text",
          condition: true
        },
        {
          name: "ClassSettings",
          icon: "fas fa-cog grey-light--text",
          condition: this.isSuperAdminUser
        }
      ].filter(({ condition }) => condition);
    }
  },

  async mounted() {
    const { name } = this.$route;
    const mySettings = { name: "MySettings" };
    // Conditionally redirect if not super-admin user
    if (!this.isSuperAdminUser && name !== mySettings.name) {
      this.$router.push(mySettings);
    }
  }
};
</script>
